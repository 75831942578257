<template>
  <div class="archive">
    <video-finite-item
        v-if="cam.id != null"
        class="archive__video"
        ref="video"
        type="archive"
        :video="cam"
        @changeFullscreen="onChangeFullSize"
        />
    <div class="archive__list" v-if="$store.getters.ARCHIVE_SCHEDULE">
      <div class="archive__list__item card" v-for="(schedules,index) of $store.getters.ARCHIVE_SCHEDULE" v-bind:key="index">
        <div class="card__top">
          <p class="card__date">{{getDate(schedules[0])}}</p>
          <p class="card__class" v-if="$store.getters.SCHOOL_CLASS_ARCHIVE.type === 0">{{$store.getters.SCHOOL_CLASS_ARCHIVE.fullName}} класс</p>
          <p class="card__class" v-if="$store.getters.SCHOOL_CLASS_ARCHIVE.type === 1">{{$store.getters.SCHOOL_CLASS_ARCHIVE.fullName}} группа</p>

        </div>
        <div class="card__bottom">
          <p class="card__day-name">{{schedules[0].dayName}}</p>
          <svg
              class="card__rotate"
              :class="{'card__rotate_inverse' : toggleIndex[index]}"
              @click="toggle(index)"
              width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="icons/стрелки/расписание/вниз" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <path d="M6.22780584,9.23431458 C6.50393412,8.95029691 6.93602979,8.92447712 7.24051579,9.15685521 L7.32774972,9.23431458 L13,15.0682667 L18.6722503,9.23431458 C18.9483786,8.95029691 19.3804742,8.92447712 19.6849602,9.15685521 L19.7721942,9.23431458 C20.0483225,9.51833224 20.073425,9.9627735 19.8475019,10.2759591 L19.7721942,10.3656854 L13.5499719,16.7656854 C13.2738437,17.0497031 12.841748,17.0755229 12.537262,16.8431448 L12.4500281,16.7656854 L6.22780584,10.3656854 C5.92406472,10.053266 5.92406472,9.54673401 6.22780584,9.23431458 Z" id="Path" fill="#788396" fill-rule="nonzero"></path>
            </g>
          </svg>
        </div>
        <div
            class="card__list"
            :class="{'card__list_visible' : toggleIndex[index]}">
          <archive-lesson-list-item
              v-for="schedule of schedules"
              v-bind:key="schedule.id"
              class="card__list__item"
              :schedule="schedule"
              @show="onShowButton(schedule.id,index)"
              @showArchive="onShowArchiveModal"
              :ref="'archiveItem'+schedule.id+''+index"
             />
        </div>
      </div>
    </div>
    <SelectArchiveModal
      @select="onSelectArchive">
    </SelectArchiveModal>
  </div>
</template>

<script>

import ArchiveLessonListItem from "../../../components/Archive/ArchiveLessonListItem";
import VideoFiniteItem from "../../../components/video/VideoFiniteItem";
import SelectArchiveModal from "../../../components/modal/SelectArchiveModal";
import moment from "moment/moment";
export default {
  name: "ArchiveListView",
  components: {VideoFiniteItem, SelectArchiveModal, ArchiveLessonListItem},
  data(){
    return {
      toggleIndex: [],
      cam: {}
    }
  },
  methods:{
    toggle(index){
      if (this.toggleIndex[index] == null){
        this.toggleIndex[index] = false
      }
      this.toggleIndex[index] = !this.toggleIndex[index]
      this.toggleIndex = [...this.toggleIndex]

      if (!this.toggleIndex[index]){
        this.onCloseButtonsForSchedule(index)
      }
    },
    onShowArchiveModal(schedule){
      if (schedule.cameras.length > 1){
        this.$modal.show("SelectArchiveModal",{cameras: schedule.cameras})
      } else {
        this.onSelectArchive(schedule.cameras[0])
      }
    },
    onShowButton(scheduleId,index){
      for(let [indexNew,schedule] of this.$store.getters.ARCHIVE_SCHEDULE.entries()){
        for (let sch of schedule){
          if (scheduleId === sch.id && index === indexNew){
            continue
          }
          this.$refs[`archiveItem${sch.id}${indexNew}`][0].hideButton();
        }
      }
    },

    onCloseButtonsForSchedule(index) {
      const schedules = this.$store.getters.ARCHIVE_SCHEDULE[index];
      for (let sch of schedules) {
        this.$refs[`archiveItem${sch.id}${index}`][0].hideButton();
      }
    },
    onSelectArchive(camera){
      this.cam = {...camera}
      this.$nextTick(() => {
        this.$refs.video.goToFullSize();
        this.$refs.video.playVideo()
      })
    },

    onChangeFullSize(isFullScreen){
      const chatraContainer = document.getElementsByClassName("chatra--webkit")[0];
      if (!isFullScreen){
        this.cam = {}
        chatraContainer.style.display = 'block'
      }
      else{
        chatraContainer.style.display = 'none'
      }
    },

    getDate(schedule){
      const momentDate = moment(schedule.begin.date)
      if (momentDate.isSame(new Date(),"day")){
        return "Сегодня"
      }
      return momentDate.format("DD.MM.YYYY")
    },
  },

  mounted(){

  }
}
</script>

<style scoped lang="scss">
  .archive{
    overflow: hidden;
    height: 100%;
    &__list{
      margin-top: 6px;
      height: auto;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 150px;

      @include media(992){
        margin-top: 44px;
      }

      @include media(767){
        margin-top: 6px;
      }

      &__item{
        margin-top: 10px;
      }

      &::-webkit-scrollbar{
        display: none;
      }
    }

    &__video{
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
    }

  }



  .card{
    width: 700px;
    min-height: 110px;
    max-height: 2000px;
    background: #FFFFFF;
    border: 1px solid #EBEDEE;
    border-radius: 4px;


    @include media(767){
      width: 375px;
    }

    &__top{
      margin-top: 14px;
      margin-left: 17px;
      margin-right: 17px;
      display: flex;
      justify-content: space-between;
    }

    &__bottom{
      margin-top: 24px;
      margin-left: 17px;
      margin-right: 21px;
      display: flex;
      justify-content: space-between;
    }

    &__list{
      max-height: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      transition: max-height .5s ease-in-out;
      margin-left: 25px;

      @include media(767){
        align-items: center;
        margin-left: 0;
      }

      &_visible{
        max-height: 2000px;
        overflow: scroll;
        transition: max-height 1s ease-in-out;
      }

      &::-webkit-scrollbar{
        display: none;
      }
    }


    &__date{
      width: 230px;
      font-family: Roboto, "sans-serif";
      font-weight: 400;
      font-size: 17px;
      color: #333942;
      letter-spacing: 0;
      text-align: left;
      line-height: 26px;

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
    }

    &__class{
      width: 98px;
      height: 26px;
      background: #F6F7F8;
      border-radius: 13px;
      font-family: Roboto, "sans-serif";
      font-weight: 400;
      vertical-align: middle;
      font-size: 13px;
      color: #788396;
      letter-spacing: 0;
      text-align: center;
      line-height: 26px;
      margin-top: 3px;
    }

    &__day-name{
      font-family: Roboto, "sans-serif";
      font-size: 13px;
      color: #606A7B;
      letter-spacing: 0;
      line-height: 18px;
      font-weight: 400;
    }

    &__rotate{
      cursor: pointer;
      &:hover{
        border-radius: 50%;
        background-color: #F4F4F4;
      }
      @include media(992){
        &:hover{
          border-radius: 0;
          background-color: transparent;
        }
      }

      transition: transform .3s;
      &_inverse{
        transform: rotate(180deg);
      }
    }
  }




</style>
